// ============================================================================
// Footer
// ============================================================================

// Variables

$footer-main-bg-colour: $bg-black;
$footer-sec-bg-colour: $bg-dark-black;
$footer-margin-top: 20px;
$footer-secondary-height: 80px;

.footer {
  background-color: $footer-main-bg-colour;
  border-bottom: $footer-secondary-height solid $footer-sec-bg-colour;
  //margin-top: $footer-margin-top;
  padding: 20px 0;
  a {
    color: #FFF;
  }
  .main {
    padding-bottom:  40px;
    @media (min-width: $screen-sm-min) { // 5 Column Grid
      .col-sm-1 {
        width: 20%;
        *width: 20%;
      }
    }
    .col-sm-1 {
      ul {
        @extend .list-unstyled;
        li {
          color: $font-light-grey;
        }
      }
      &:nth-of-type(1) {
        ul {
          li {
            &:nth-of-type(1) {
              &:before {
                content: "Phone:";
              }
            }
            &:nth-of-type(2) {
              &:before {
                content: "Address:";
              }
            }
            &:nth-of-type(3) {
              &:before {
                content: "";
              }
            }
            &:nth-of-type(4) {
              &:before {
                content: "";
              }
            }
            &:nth-of-type(5) {
              &:before {
                content: "Email:";
              }
            }
            &:before {
              display: inline-block;
              width: 60px;
            }
          }
        }
      }
      &:nth-of-type(5) {
        ul {
          li {
            &:nth-of-type(1) {
              &:before {
                font-family: FontAwesome;
                content: "\f09a";
              }
            }
            &:nth-of-type(2) {
              &:before {
                font-family: FontAwesome;
                content: "\f099";
              }
            }
            &:nth-of-type(3) {
              &:before {
                font-family: FontAwesome;
                content: "\f0e1";
              }
            }
            &:nth-of-type(4) {
              &:before {
                font-family: FontAwesome;
                content: "\f0d5";
              }
            }
            &:nth-of-type(5) {
              &:before {
                font-family: FontAwesome;
                //content: "\f0d2";
              }
            }
            &:before {
              display: inline-block;
              width: 20px;
            }
          }
        }
      }
    }
  }
  .secondary {
    margin-bottom: -$footer-secondary-height;
    ul {
      @extend .list-unstyled;
      color: #666;
      li {
        display: inline-block;
        padding: 0 10px;
      }
    }
  }
  h6 {
    color: $font-light-grey;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 400;
    margin: 10px 0;
  }
}


