// ============================================================================
// Header
// ============================================================================

$logo-image-height: 40px;
$logo-bg-color: rgba(000, 000, 000, 0.8);

$hero-bg-base-height: 400px;

$box-height: 80px;

$welcome-height: 60px;
$welcome-message-height: 40px;
$welcome-bg-color: #FFF;
$welcome-sm-bg-color: rgba(0,0,0,.6);

$header-height: ($navbar-height + $texture-height + $logo-height + ($logo-margin * 2) + 5px);

// Banner structure as follows:
// Nav first element
// Hero image (css background) negative margin Title
// Welcome negative margin hero image

.header {
  .carousel {
    top: -$header-height;
    margin-bottom: -$header-height;
    .weather-widget-wrapper {
      float: right;
      position: relative;
      z-index: 1000;
      width: 100%;
      max-width: 120px;
      .widget {
        position: absolute;
        top: 150px;
        padding: 5px;
        background-color: rgba(255,255,255,.6);
        .weather-label {
          font-size: 10px;
          line-height: 10px;
          text-transform: uppercase;
        }
        .weather-value {

        }
      }
    }
  }
  .boxes {
    margin-top: -($welcome-height + $header-height + $box-height);
    div[class^='col-'] {
      padding: 0 7.5px;
      &:first-of-type {
        padding-left: 15px;
      }
      &:last-of-type {
        padding-right: 15px;
      }
    }
    .box {
      height: $box-height;
      padding: 10px;
      background-color: rgba(000, 000, 000, .6);
      p {
        a {
          color: #FFF;
          font-size: 16pt;
        }
        span {
          color: $font-green;
          font-size: 12pt;
        }
      }
    }
  }
  .welcome {
    margin-top: ($navbar-height + 10px);
    @media (min-width: $screen-sm-min) {
      height: $welcome-height;
      margin-top: 0;//-($welcome-height + $header-height);
    }
    .msg {
      padding: (($welcome-height - $welcome-message-height) / 2) 20px;
      background-color: $welcome-bg-color;
      .content {
        padding-top: 10px;
      }
      h4 {
        font-size:16px;
        margin: 0;
        @media (min-width: $screen-sm-min) {
          font-size: 20px;
          line-height: $welcome-message-height;
        }
      }
    }
    &.small {
      @media (min-width: $screen-sm-min) {
        margin-top: -($welcome-height + $header-height + 10px);
        margin-bottom: 10px;
      }
      .msg {
        display: inline-block;
        background-color: $welcome-sm-bg-color;
        h4 {
          color: #FFF;
        }
      }
    }
  }
}