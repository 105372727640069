// ============================================================================
// Buttons
// ============================================================================

.btn {
  white-space:normal !important;
  word-wrap:break-word;
}

.btn-green {
  @extend .btn;
  background-color: $brand-green;
  color: white;
  border: 1px solid darken($brand-green, 5%);
  border-radius: 0;
  padding: 10px 20px;
  font-weight: bold;
  text-transform: uppercase;
  &:hover, &:active, &:focus {
    color: #FFF;
    background-color: darken($brand-green, 5%)
  }
  &.current {
    color: #FFF;
    background-color: darken($brand-green, 5%)
  }
}

.btn-lrg {
  display: block;
  width: 100%;
}