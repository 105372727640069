.related-galleries {
  margin: 10px 0 20px;
}

.gallery-filters {
  margin-bottom: 10px;
  button {
    @extend .btn;
    background-color: transparent;
    font-size: 14px;
    text-transform: uppercase;
    &:hover, &:active, &:focus {
      box-shadow: none !important;
      outline: 0 !important;
      color: $font-green;
    }
  }
}

.caption {
  text-align: center;
  margin: 10px 0;
  font-size: 13px;
  color: #777;
  p {
    font-style: italic;
  }
}
