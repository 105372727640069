.contact {
  ul {
    @extend .list-unstyled;
    li {
      &:nth-of-type(1) {
        &:before {
          content: "Phone:";
        }
      }
      &:nth-of-type(2) {
        &:before {
          content: "Address:";
        }
      }
      &:nth-of-type(3) {
        &:before {
          content: "";
        }
      }
      &:nth-of-type(4) {
        &:before {
          content: "Email:";
        }
      }
      &:nth-of-type(5) {
        &:before {
          content: "Website:";
        }
      }
      &:before {
        display: inline-block;
        width: 60px;
      }
    }
  }
}