// ============================================================================
// Typography Stylesheet
//
// Defines additional styles which can be applied in the CMS
// ============================================================================



// Bootstrap Styles
// Styles which you want users to be able to select in the CMS
// ----------------------------------------------------------------------------

.lead {
  // For the style to register, it needs some kind of definition.
  // Just apply the default display value
  display: block;
}

h1, h4 {
  color: $font-green;
}

h2 {
  line-height: 26px;
}

h3, h5, h6 {
  color: $font-grey;
}

a {
  font-weight: bold;
  color: $font-green;
  &:hover {
    color: darken($font-green, 10%);
    text-decoration: none;
  }
}

// Custom Styles
//
// Styles which will appear in the Styles dropdown in the CMS
// ----------------------------------------------------------------------------

.btn-default {
  display: inline-block;

  @extend .btn;
}
