.form {
  form {
    label {
    }
    input[type="text"], input[type="email"], textarea {
      border: 0;
      border-radius: 0;
      box-shadow: none;
      background-color: $bg-light-grey;
    }
    input[type="checkbox"] {
      display:none !important;
    }
    input[type="checkbox"] + label:after {
      content: "";
      display: inline-block;
      width: 25px;
      height: 25px;
      vertical-align:middle;
      margin-left: 8px;
      background-color: $bg-light-grey;
      box-shadow: none;
      border-radius: 0;
    }
    input[type="checkbox"]:checked + label:after {
      font-family: "FontAwesome";
      content:"\f00c";
      color:white;
      background-color: $bg-dark-grey;
      text-align:center;
      line-height:25px;
      text-shadow:none;
    }
    input[type="submit"] {
      @extend .btn-green;
    }
    .checkboxset {
      li {
        display: inline-block;
      }
    }
    .field {
      &.nolabel {
        margin-bottom: 0;
      }

      &.nocaptcha {
        margin-bottom: 15px;
      }
    }
  }
  .underline {
  }
}

.gMap {
  min-height: 400px;
  .gm-style-iw {
    color: #000;
  }
}