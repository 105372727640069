// ============================================================================
// Scaffolding
// ============================================================================

.well {
  background-color: #FFF;
  border-radius: 0;
  border: 0;
  overflow: auto;
  overflow-y: hidden;
  height: 100%;
}

.img-responsive {
  margin: 0 auto;
}

.breadcrumb {
  margin: 10px 0;
  > li {
    text-transform: uppercase;
    + li:before {
      content: "#{$breadcrumb-separator}"; // Unicode Override
    }
    a {
      text-decoration: none;
      color: $font-grey;
      &:hover {
        text-decoration: none;
        color: $font-green;
      }
    }
  }
}

.full {
  @media (min-width: $screen-sm-min) {
    display: table;
    > div[class*="col-"] {
      float: none;
      display: table-cell;
      vertical-align: top;
    }
  }
}

.sponsors {
  .img-responsive {
    margin-bottom: 20px;
  }
}


