.side {
  h3 {
    color: $font-green;
    @extend %underline;
  }
}

.list-group {
  .list-group-item {
    border: 0;
    color: $font-grey;
  }
  > a {
    text-transform: uppercase;
    font-size: 17px;
    &:hover, &:focus {
      background-color: transparent;
    }
    &.section, &.current {
      color: $font-green;
    }
  }
  > div {
    > a {
      margin-left: 20px;
      &:before {
        display: inline-block;
        width: 20px;
        text-align: center;
        content: "-";
      }
      &:hover, &:focus {
        background-color: transparent;
      }
      &.current {
        color: $font-green;
      }
    }
  }
  &.panel {
    box-shadow: none;
    margin-bottom: 0;
    min-height: 100%;
  }
}

.news-letter {
  h4,p {
    margin-top: 20px;
  }
  form {
    .form-group {
      .form-control {
        border: 0;
        border-radius: 0;
        box-shadow: none;
        background-color: $bg-light-grey;
        margin-top: 15px;
      }
    }
  }
  .btn-green {
    margin-top: 15px;
  }
}