.blogSummary {
  // Force grid by forcing heights
  .img-responsive {
    //height: 200px;
    width: 100%;
  }
  .well {
    //height: 200px;
    h5 {
      text-transform: uppercase;
      @extend %underline;
    }
    h3 {
      margin-top: 5px;
    }
  }
}

.blogEntry {
  .img-responsive {
    margin: 0;
  }
  h3 {
    text-transform: uppercase;
  }
  h6 {
    color: $font-green;
    span {
      @extend %underline;
    }
  }
}

.blogSideBar {
  h3 {
    @extend %underline;
  }
}

.WidgetHolder {
  @extend .well;
  h3 {
    text-transform: uppercase;
  }
  ul {
    @extend .list-unstyled;
    li {
      a {
        text-transform: uppercase;
        color: $font-grey;
        line-height: 190%;
        &:hover {
          color: $font-green;
        }
      }
    }
  }
}

.comments-holder-container {
  @extend .form;
  .comments-holder {
    margin-bottom: 30px;
    ul {
      padding: 0;
      li {
        padding: 0;
        div {
          border: 0;
          padding: 0;
          p {
            font-size: 16px;
          }
        }
        p {
          a {
            font-size: 14px;
          }
          span {
            font-size: 14px;
            color: $font-green;
          }
        }
      }
    }
  }
  h4 {
    color: $font-grey;
    &:first-of-type {
      @extend %underline;
    }
  }
}