// ============================================================================
// Home Page Layout
// ============================================================================

// Variables

.content {
  .lead {

  }
  .secondary {
    .img-responsive {
      margin-bottom: 20px;
    }
  }
}

.cta-buttons {
  margin-top: 20px;
}

.cta-white, .cta-green, .msg {
  .btn-green {
    display: block;
    width: 100%;
  }
}

.cta-white {
  .content {
    padding-top: 12px;
  }
}

.cta-green {
  .well {
    background-color: $brand-green;
    * {
      display: inline-block;
      color: #FFF !important;
      @media (min-width: $screen-sm-min) {
        display: inline;
      }
    }
  }
}