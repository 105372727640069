//.carousel-fade {
//  .carousel-control {
//    z-index: 2;
//  }
//  .carousel-inner {
//    .item {
//      opacity: 0;
//      transition-duration: 2s;
//    }
//    .active {
//      opacity: 1;
//      .left,
//      .right {
//        left: 0;
//        opacity: 0;
//        z-index: 1;
//      }
//    }
//    .next.left,
//    .prev.right {
//      opacity: 1;
//    }
//  }
//}

.carousel-fade .carousel-inner .item {
  opacity: 0;
  -webkit-transition-property: opacity;
  -moz-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity;
  transition-duration: 2s;
}
.carousel-fade .carousel-inner .active {
  opacity: 1;
}
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  left: 0;
  opacity: 0;
  z-index: 1;
}
.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
  opacity: 1;
}
.carousel-fade .carousel-control {
  z-index: 2;
}

.carousel {
  z-index: 0;
  .carousel-inner {
    .item {
      .img-responsive {
        width: 100%; // IE Fix
      }
      .carousel-caption {
        top: 150px;
        padding: 0;
        left: 0;
        right: 0;
        h1, h4 {
          color: #FFF;
        }
        h1 {
          font-size: 20px;
          font-weight: 600;
          @media (min-width: $screen-md-min) {
            font-size: 35px;
          }
        }
        h4 {
          font-size: 16px;
          font-weight: 400;
          @media (min-width: $screen-md-min) {
            font-size: 20px;
          }
        }
        p {
          font-size: 20px;
        }
      }
    }
  }
}