$texture-height: 30px;
$logo-height: 40px;
$logo-margin: 10px;

.navbar {
  // Collapsed Styles
  background-color: #FFF;
  border: 0;
  border-radius: 0;
  margin-bottom: 0;
  z-index: 955; // Overlay carousel as well as indicators
  @media (max-width: $screen-lg) {
    top: 0;
    left: 0;
    right: 0;
  }
  .texture {
    height: $texture-height;
  }
  .logo {
    height: $logo-height;
    &.img-responsive {
      display: inline-block;
      margin: $logo-margin;
      @media (min-width: $screen-sm-min) {
        display: block;
        margin: $logo-margin auto;
      }
    }
  }
  .navbar-header {
    display: inline-block;
    float: right;
    margin-top: 10px;
  }
  .navbar-collapse {
    @media (min-width: $screen-sm-min) {
      text-align: center;
    }
    .navbar-nav {
      @media (min-width: $screen-sm-min) {
        float: none;
        display: inline-block;
      }
      li {
        a {
          color: $font-grey; // Navbar link colour
          // background-color: ; // Navbar link background colour
          font-weight: bold;
          text-transform: uppercase;
          &:hover, &:focus, &:active {
            color: $font-green; // Navbar link colour when hovered/focused/activated
            background-color: transparent; // Navbar link background colour when hovered/focused/activated
          }
        }
        &.current {
          a {
            color: $font-green; // Navbar link colour when current (SS)
            background-color: transparent; // Navbar link background colour when current (SS)
            border-bottom: 5px solid $brand-green;
            @media (min-width: $screen-sm-min) {
              margin-bottom: -5px;
            }
            &:hover, &:focus, &:active {
              // color: ; // Navbar link colour when hovered/focused/activated and current (SS)
              // background-color: ; // Navbar link background colour when hovered/focused/activated and current (SS)
            }
          }
        }
      }
      .open {
        a {
          color: $brand-green; // Navbar link colour when open
          background-color: transparent; // Navbar link background colour when open
          &:hover, &:focus, &:active {
            color: $brand-green; // Navbar link colour when open and hovered/focused/activated
            background-color: transparent; // Navbar link background colour when open and hovered/focused/activated
          }
        }
        .dropdown-menu {
          background-color: $brand-green; // Navbar dropdown background colour
          border-color: darken($brand-green, 10%); // Navbar dropdown border colour
          border-radius: 0;
          padding: 0;
          margin-top: 5px;
          li {
            &:nth-last-of-type(1) {
              a {
                border-bottom: 0;
              }
            }
            a {
              // background-color: ; // Navbar dropdown link background colour
              // border-color: ; // Navbar dropdown link Navbar border colour
              color: #FFF;
              padding: 15px;
              border-bottom: 1px solid #FFF;

              &:hover, &:focus, &:active {
                // color: ; // Navbar dropdown link colour when hovered/focused/activated
                // background-color: darken($brand-green, 10%); // Navbar dropdown link background colour when hovered/focused/activated
              }
            }
            &.current {
              a {
                // color: ; // Navbar dropdown link colour when current (SS)
                // background-color: ; // Navbar dropdown link background colour when current (SS)
                &:hover, &:focus, &:active {
                  // color: ; // Navbar dropdown link colour when hovered/focused/activated and current (SS)
                  // background-color: ; // Navbar dropdown link background colour when hovered/focused/activated and current (SS)
                }
              }
            }
          }
        }
      }
    }
  }
}