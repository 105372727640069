// ============================================================================
// Search
// ============================================================================

// Nav Search Form
// ----------------------------------------------------------------------------

.search-form {
  padding-top: 10px;
  padding-bottom: 10px;
  @media (min-width: $screen-sm-min) {
    margin-left: -15px;
    margin-right: -15px;
  }
  .input-group {
    width: 100%;
  }
  input {
    background-color: $bg-dark-grey;
    color: #FFF;
    border-radius: 0;
    box-shadow: none;
    &:focus {
      outline: none;
      border-color: #ccc;
      box-shadow: none;
    }
    &::-webkit-input-placeholder { /* WebKit browsers */
      color:    #FFF;
    }
    &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
      color:    #FFF;
      opacity:  1;
    }
    &::-moz-placeholder { /* Mozilla Firefox 19+ */
      color:    #FFF;
      opacity:  1;
    }
    &:-ms-input-placeholder { /* Internet Explorer 10+ */
      color:    #FFF;
    }
  }
  .input-group-btn {
    .btn {
      border-left: 0;
      background-color: $bg-dark-grey;
      border-radius: 0;
      color: #FFF;
      &:hover {
        border-color: #ccc;
      }
    }
  }
}

#Nav_SearchForm_action_results {
  @media only screen and (max-width: $screen-xs-max) {
    display: block;
    width: 100%;
  }
}